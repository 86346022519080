<template>
  <div class="app-body" id="app" v-bind:style="{ backgroundImage: pageBackground ? 'url(' + pageBackground + ')' : '' }">
    <div v-if="!pageData" class="lds-ellipsis"><img src="@/assets/loader.gif"> Get ready to spin!</div>
    <div v-if="pageData" class="container">
      <div class="main-body">
        <div class="body-wrapper">
          <div class="left-column">
            <div class="logo">
              <img :src="pageLogo" alt="Logo" v-if="pageLogo"/>
              <div class="logo-div" v-if="!pageLogo && pageLogoText">
                <img class="modal-logo" src="@/assets/modal-logo.png"/>
                <div class="logo-div--inner" ><span alt="Logo" class="logo-text">{{this.pageLogoText}}</span></div>
                </div>
              <div v-if="!pageLogoText && !pageLogo">
                <img v-if="geo !== 'br'" :src="require('@/assets/main-logo.png')" />
                <img v-else :src="require('@/assets/logo-' + this.geo + '.png')" />
              </div>
            </div>
            <div class="main-content">
              <h1 v-bind:style="{ 'color': pageTitleColor ? pageTitleColor : '' }">{{this.pageTitle || "Win 1000s of Free Spins!"}}</h1>
              <h2 v-bind:style="{ 'color': pageSubtitleColor ? pageSubtitleColor : '' }">{{ this.pageSubtitle || "1 Free Spin remaining" }}</h2>
            </div>
            <div class="button-desk">
              <button :class="{ spun: this.spinning}"
                      class="button button--spin"
                      v-bind:style="{ 'backgroundColor': pageButtonColor ? pageButtonColor : '', 'color': pageButtonTextColor ? pageButtonTextColor : '' }"
                      @click="spinWheel"><span class="rotate-arrow">&#8634;</span> {{ this.pageButtonText || "Spin Now" }}</button>
            </div>
          </div>
          <div class="right-column">
            <Wheel v-if="pageData" :pageData="pageData" :class="spinningClass"/>
          </div>
          <div class="button-mobile">
            <button class="button button--spin" v-bind:style="{ 'backgroundColor': pageButtonColor ? pageButtonColor : '', 'color': pageButtonTextColor ? pageButtonTextColor : '' }"
                    @click="spinWheel"><span class="rotate-arrow">&#8634;</span> {{ this.pageButtonText || "Spin Now" }}</button>
          </div>
          <Modal :pageData ="pageData" :trackid="trackingData" :brandData="brandData" v-if="spun"/>
        </div>
      </div>
    </div>
    <div v-if="pageData" class="footer-wrapper" v-bind:style="{ 'backgroundColor': disclaimerBackgroundColor ? disclaimerBackgroundColor : '' }">
      <div class="container">
        <div class="footer-content">
          <!--- footer logo --->
          <div class="footer-logo"><img :src="this.pageFooterLogo || require('@/assets/modal-logo.png')"/></div>
          <!-- eslint-disable max-len - Disclaimer -->
          <p v-bind:style="{ 'color': disclaimerTextColor ? disclaimerTextColor : '' }">{{ this.disclaimerText || "Disclaimer: We are a wholly independent 3rd party offering casino related news, view, hints &amp; tips and all opinions are our own and in no way those of the brand or brands featured on these pages. all of our facts can be substantiated. all casinos display their unique t&amp;cs on their sites and should always be reviewed prior to playing. any t&amp;cs specifically related to the advice in these articles can be found below and also on the operator’s website. to piece together the casino rankings and ratings seen on this site, we’ve taken data from a number of different sources, including user experience recommendations and comments. on occasion, we’ve also taken into account our own ranking and rating algorithms, in addition to some third party ranking agencies. to keep this information free of charge, we might at times generate proceeds from the service providers featured. while we take great care to ensure all the information presented is up-to-date and accurate, we hereby disclaim all express guarantees and warrants featured. that’s with regards to everything from marketability to applicability and relevance of the information. this website, including its content and services, is provided ‘as is’. as such, your use and reliance on the information presented are entirely at your own risk." }}</p>
          <div class="footer-icons">
          <a v-for="(icon, index) in footerIcons" :key="index" :class="'footer-icon-link icon-' + slugify(icon.label)" :href="icon.value" target="_blank" rel="noindex nofollow noopener noreferrer">
            <img :class="'lazy icon-' + slugify(icon.label)" :src="require('@/assets/icons/' + slugify(icon.label) + '.png')" :alt="icon.label">
          </a>
        </div>
          <div><a class="private-policy-link" :href="this.privatePolicyUrl">Privacy Policy</a></div>
        </div>
      </div>
      <!-- Start of HubSpot Embed Code -->
      <script type="application/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/25231212.js?businessUnitId=15204561"></script>
      <!-- End of HubSpot Embed Code -->
      <!-- <script type="application/javascript" charset="utf-8" src="http://js.hubspot.com/forms/current.js"></script> -->
    </div>
  </div>
</template>

<script>
import Wheel from '@/components/Wheel.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'App',
  components: {
    Wheel,
    Modal,
  },
  data() {
    return {
      spinning: false,
      spun: false,
      brandData: null,
      trackingData: null,
      pageData: null,
      pageBackground: null,
      pageColor: null,
      pageLogo: null,
      pageTitle: null,
      pageTitleColor: null,
      pageSubtitle: null,
      pageSubtitleColor: null,
      pageButtonText: null,
      pageButtonTextColor: null,
      pageButtonColor: null,
      disclaimerText: null,
      disclaimerTextColor: null,
      disclaimerBackgroundColor: null,
      pageFooterLogo: null,
      geo: null,
      vertical: null,
      networkName: null,
      pageAb: null,
      landingPage: null,
      firstVisit: window.sessionStorage.getItem('firstVisit') || null,
      pageLogoText: null,
      footerIcons: null,
      privatePolicyUrl: null,
    };
  },
  methods: {
    spinWheel() {
      if (this.spinning || this.spun) return;
      this.spinning = true;
      this.getBrand();
      setTimeout(() => {
        this.spun = true;
        this.$confetti.start({
          particles: [
            {
              type: 'rect',
            },
          ],
        });
      }, 5000);
    },
    // brand json req
    async getBrand() {
      try {
        // get LandingPage value from url param landingpage (set in created hook)
        const response = await this.axios.get(`${process.env.VUE_APP_API}/brands/v1/offer/?hostname=${this.landingPage}`);
        this.brandData = response.data.brandData !== undefined ? response.data : null;
      } catch (error) {
        console.log(error);
      }
    },
    getParameterByName(paramName, url = window.location.href) {
      const name = paramName.replace(/[[\]]/g, '\\$&');
      const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);
      if (!results) return false;
      if (!results[2]) return false;
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    async getPageData() {
      try {
        this.trackingData = ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, (c) =>
        // eslint-disable-next-line no-bitwise, no-mixed-operators, implicit-arrow-linebreak
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
        // eslint-disable-next-line prefer-template
        this.trackingData += 'tt';
        const response = await this.axios.get(`${process.env.VUE_APP_API}/data/v1/page/?hostname=${this.landingPage}`);
        this.pageData = response.data !== undefined ? response.data : null;
        this.pageBackground = this.pageData.pageBackground || this.pageBackground;
        this.pageColor = this.pageData.pageColor;
        this.pageLogo = this.pageData.pageLogo;
        this.pageLogoText = this.pageData.pageLogoText;
        this.pageTitle = this.pageData.pageTitle || this.pageTitle;
        this.pageTitleColor = this.pageData.pageTitleColor;
        this.pageSubtitle = this.pageData.pageSubtitle;
        this.pageSubtitleColor = this.pageData.pageSubtitleColor;
        this.pageButtonText = this.pageData.pageButtonText;
        this.pageButtonTextColor = this.pageData.pageButtonTextColor;
        this.pageButtonColor = this.pageData.pageButtonColor;
        this.disclaimerText = this.pageData.disclaimerText;
        this.disclaimerTextColor = this.pageData.disclaimerTextColor;
        this.disclaimerBackgroundColor = this.pageData.disclaimerBackgroundColor;
        this.pageFooterLogo = this.pageData.pageFooterLogo;
        this.pageWheelCenterImage = this.pageData.pageWheelCenterImage;
        this.privatePolicyUrl = this.pageData.privatePolicyUrl;
        this.networkName = this.pageData.networkName;
        this.geo = this.pageData.geo;
        this.vertical = this.pageData.vertical;
        this.pageAb = this.pageData.pageAb;
        this.footerIcons = this.pageData.footerIcons;
        if (!window.sessionStorage.getItem('firstVisit')) {
          sessionStorage.setItem('firstVisit', true);
          this.saveVisit();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async saveVisit() {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const data = {
          eventType: 'visit',
          trackid: this.trackingData || '',
          networkName: this.networkName || '',
          domain: this.getSiteLocation() || '',
          page: '/',
          pageId: '0',
          utmSource: this.getParameterByName('utm_source') || 'false',
          utmMedium: this.getParameterByName('utm_medium') || 'false',
          utmTerm: this.getParameterByName('utm_term') || 'false',
          utmContent: this.getParameterByName('utm_content') || 'false',
          utmCampaign: this.getParameterByName('utm_campaign') || 'false',
          gclid: this.getParameterByName('gclid') || 'false',
          keyword: this.getParameterByName('keyword') || 'false',
          matchtype: this.getParameterByName('matchtype') || 'false',
          device: this.getParameterByName('device') || 'false',
          userAgent: navigator.userAgent || '',
          adpos: this.getParameterByName('adpos') || 'false',
          geo: this.geo || '',
          vertical: this.vertical || '',
          ab: this.pageAb || '',
          activeAgg: '',
          blockDetected: false,
          wasRightClick: 0,
        };
        await this.axios.post(`${process.env.VUE_APP_CLICK_API}/api/v1/user/save`, JSON.stringify(data), config);
      } catch (error) {
        console.log(error);
      }
    },
    getSiteLocation() {
      return window.location.hostname;
    },
    slugify(text) {
      return text.toString().toLowerCase().trim()
        .replace(/\s+/g, '-')// Replace spaces with -
        .replace(/[^\w-]+/g, '')// Remove all non-word chars (remove unnecessary escape character)
        .replace(/--+/g, '-')// Replace multiple - with single -
        .replace(/^-+/, '')// Trim - from start of text
        .replace(/-+$/, '');// Trim - from end of text
    },
    loadSvg(filename) {
      // Define the logic for the load_svg function here
      return fetch(`path/to/svgs/${filename}.svg`)
        .then((response) => response.text())
        .then((svgText) => {
          const parser = new DOMParser();
          const svg = parser.parseFromString(svgText, 'image/svg+xml').querySelector('svg');
          return svg;
        })
        .catch((error) => console.error(`Error loading SVG ${filename}: ${error}`));
    },
  },
  computed: {
    spinningClass() {
      const maxNumber = 5;
      const minNumber = 1;
      return this.spinning ? `spinning-${Math.floor(Math.random() * (maxNumber - minNumber) + minNumber)}` : 'static';
    },
  },
  created() {
    this.landingPage = this.getParameterByName('lpg') || 'spin.123casinos.com';
    this.getPageData();
  },
};

</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

.logo-text{
  font-family: 'Archivo Black', sans-serif;
  font-size: 62px;
  line-height: 0.8;
  word-spacing: -10px;
  text-transform: uppercase;
  background: rgb(243,228,153);
  background: linear-gradient(to right,  rgba(243,228,153,1) 0%,rgba(200,152,56,1) 20%,rgba(243,228,153,1) 40%,rgba(200,152,56,1) 60%,rgba(243,228,153,1) 80%,rgba(200,152,56,1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.logo-div{
  width: 328px;
  max-width: 100%;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 0.5rem);
    height: calc(100% + 0.5rem);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    background: rgb(243,228,153);
    background: linear-gradient(to right,  rgba(243,228,153,1) 0%,rgba(200,152,56,1) 20%,rgba(243,228,153,1) 40%,rgba(200,152,56,1) 60%,rgba(243,228,153,1) 80%,rgba(200,152,56,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3e499', endColorstr='#c89838',GradientType=1 );
  }
}
.logo-div--inner {
  position: relative;
  z-index: 1;
  background-color: #402689;
  border-radius: 12px;
  padding: 1.5rem 1rem 0.5rem 1rem;
  max-height: calc(100vh - 3rem);
  overflow: auto;
  margin-top: 1rem;
  text-align: center;
}
.modal-logo {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 155px;
  z-index: 2;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 15px;
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
  text-align: center;
  color: white;
}

.footer-icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;

  a {
    margin: .75rem;
  }

  svg {
    fill: #fff;
    display: block;
  }

  img {
    display: block;
  }
}

.plus-18-21 {
  width: 25px;

  &:not(:first-child) {
    margin-left: .75rem;
  }
}

.gamble-aware-icon, .icon-take-time-to-think {
  width: 150px;
}
/* Serbia Icons */
.icon-18-plus-serbia{
  width: 30px;
}
.icon-serbia-problem-gambling{
  width: 50px;
}
.icon-drajzerova-logo-second{
  width: 50px;
}
.icon-drajzerova-logo-first{
  width: 100px;
}
.icon-serbia-ministry-of-health{
  width: 100px;
}
/* End of Serbia Icons */
.gamcare-footer-icon {
  width: 100px;
}
.gambling-therapy-icon {
  width: 100px;
}
.icon-gordon-moody {
  width: 100px;
}
.icon-evalu-jeu {
  width: 100px;
}

.icon-ecogra {
  width: 110px;
}

.icon-ncpg {
  width: 100px;
}

.icon-800-gambler {
  width: 100px;
}

.icon-nj-responsible-gambling {
  width: 60px;
}
.icon-stopspillet {
  width: 100px;
}
.icon-ludomani {
  width: 100px;
}
.gamstop-icon {
  width: 100px;
}
.icon-gamcare {
  width: 100px;
}
.icon-rofus {
  width: 100px;
}
.icon-igc {
  width: 130px;
}
.icon-onjn-romania {
  width: 65px;
}
.icon-gamblers-anonymous {
  width: 110px;
}
.icon-michigan-gaming-control-board {
  width: 70px;
}
.icon-srij {
  width: 60px;
}
.icon-sicad {
  width: 130px;
}
.icon-adg-problem-gambling {
  width: 230px;
}
.icon-spillemyndigheden {
  width: 130px;
}
.icon-viradoojogobr {
  width: 300px;
}
.icon-jogadoresanonimosbr {
  width: 60px;
}
.icon-loketkansspelnl {
  width: 200px;
}
.icon-agognl {
  width: 100px;
}
.icon-24x7handsnl {
  width: 150px;
}
.icon-cruksnl {
  width: 100px;
}
.icon-juegosysorteos-mx {
  width: 170px;
}
.icon-segob-mx {
  width: 140px;
}
.icon-gambling-care {
  width: 200px;
}
.icon-gambling-therapy{
  width: 100px;
}
.icon-extern {
  width: 100px;
}
.icon-18-plus-chile {
  width: 140px;
}
.icon-18-plus{
  width: 30px;
}
.icon-21-plus{
  width: 30px;
}

.footer-logo {
  margin: auto;
  text-align: center;
  max-width: 150px;
}

.private-policy-link{
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  a:visited{
    color:#fff;
  }
}

.button--spin {
  display: flex;
  align-items: center;
  &:not(.spun):hover {
    background-color: #C4335E;
    color: #ffffff;
    box-shadow: 0px 4px 6px 2px rgba(206, 159, 58, .3);
    transform: scale(1.075);
    .rotate-arrow  {
      transform: rotate(-360deg);
    }
  }
  &.spun {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.rotate-arrow {
  font-weight: 700;
  font-size: 1.5em;
  margin-right: .5rem;
  transform: rotate(45deg);
  pointer-events: none;
  transition: 0.3s ease-in-out all
}
.logo {
  img {
    width: 300px;
    display: block;
  }
}
.main-body {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  min-height: 100vh;
}
.body-wrapper {
  display: flex;
  width: 100%;
}
.footer-wrapper {
  background-color: #0D2954;
  padding: 1rem 0;
}
.footer-content {
  color: #B2B2B2;
  font-size: 12px;
  font-family: 'Roboto';
  font-weight: 400;
}
.right-column {
  flex: 1;
  margin-left: 4rem;
}
.button-desk {
  margin-top: 2rem;
}
.main-content {
  h1 {
    font-size: 2.25em;
    line-height: 1;
    color: #402689;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, .3);
    margin: 1.5rem 0 3rem;
  }
  h2 {
    margin: 0;
    font-size: 2em;
    line-height: 1;
    color: #fff;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, .3);
  }
}
@media (min-width: 1024px) {
  .button-mobile {
    display: none;
  }
}
@media (max-width: 1023px) {
  .main-body {
    display: block;
    padding-bottom: 2rem;
    //background: url('./assets/spin-mobile-bg.jpg');
    background-size: cover;
    background-position: top;
  }
  .app-body {
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
  }
  .body-wrapper {
    display: block;
    padding: 0;
  }
  .wheel {
    height: unset;
    width: unset;
  }
  .logo {
    margin-left: 0;
    img {
      margin: 0 auto;
      width: 160px;
    }
  }
  .button-desk {
    display: none;
  }
  .main-content {
    padding: 0 1rem;
    text-align: center;
    h1 {
      font-size: 2em;
      margin: 1rem 0;
    }
    h2 {
      background: #402689;
      font-size: 1.5em;
      padding: .5rem;
      margin: 0 -1rem;
    }
  }
  .footer-wrapper {
    margin-top: 0;
    padding: 1rem;
  }
  .footer-content {
    width: 100%;
  }
  .right-column {
    margin: 0;
    padding: 0 1rem;
  }
  .button-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  .logo-div{
    margin: auto;
  }
  .footer-icons{
    flex-direction: column;
  }
}
@media (max-width: 500px) {
  .main-content {
    h1 {
      font-size: 1.5em;
    }
    h2 {
      font-size: 1.25em;
    }
  }
  .body-wrapper {
    display: flex;
    flex-direction: column;
  }
  .left-column {
    order: 1;
  }
  .right-column {
    order: 3;
  }
  .button-mobile {
    order: 2;
  }
  .button-mobile {
    margin: 1rem 0 .5rem;
  }

  .button--spin {
    font-size: 1.25em;
    padding: .5rem 2rem;
  }
}
</style>
